.slider {
  overflow: hidden;
  margin: 0 1rem;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.slider-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.slider-item img {
  width: 100%;
  height: auto; /* Ensure image aspect ratio is maintained */
  max-height: 100%; /* Optional: Set a maximum height for the images */
}

.indicators {
  display: flex;
  justify-content: center;
}

.indicators > button {
  margin: 5px;
}

.indicators > button.active {
  background-color: green;
  color: #fff;
}

@media (max-width: 768px) {
  .slider {
    border-radius: 15px;
    height: auto;
    opacity: 0.65;
  }

  .right-column {
    position: fixed;
  }
}
