#faq-container {
    padding-bottom: 4rem;
}

#faq-container h3 {
    font-family: "Inter", sans-serif;
    margin-bottom: 4rem;
}

.accordion-wrapper {
    background-color: transparent;
    border-bottom: 2px solid #fff;
}

.accordion-wrapper + * {
    margin-top: 0.5em;
}

.accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
    text-align: center;
}

.accordion-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
    font-weight: 600;
    cursor: pointer;
    color: #fff;
    border-radius: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accordion-title h1 {
    color: #fff;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.accordion-title:hover,
.accordion-title.open {
    color: #fff;
}

.accordion-title.open::after {
    content: "";
    border-top: 0;
    border-bottom: 5px solid;
}
