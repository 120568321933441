.calendly-inline-widget,
.calendly-inline-widget *,
.calendly-badge-widget,
.calendly-badge-widget *,
.calendly-overlay,
.calendly-overlay * {
  font-size: 4em;
  font-family: "Kallisto";
}

.calendly-inline-widget {
  width: 100% !important;
}

.booking-page-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 5rem;
}

#booking-container {
  width: 50%;
  transform: scale(0.8);
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.booking-footer #footer {
  width: 95vw;
  padding: 0 2rem;
}

@media only screen and (min-width: 375px) and (max-width: 768px) {
  .booking-page-container {
    flex-direction: column-reverse;
    padding: 0;
    margin-top: 15vh;
  }

  .booking-page-container #booking-container {
    width: 100%;
    margin-top: 1rem;
  }

  .booking-page-container .session-duration-dropdown button {
    font-size: medium;
    padding: 1rem;
    margin-top: 2rem;
  }

  #booking-container .filter .session-selection {
    flex-direction: column;
    margin-bottom: 2rem;
    gap: 20px;
  }

  #booking-container
    .filter
    .session-selection
    .session-duration-container
    button {
    margin-top: 0;
  }

  .booking-footer #footer {
    padding: 0;
  }
}
