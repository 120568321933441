#main-page {
  width: 100vw;
  height: 100%;
  display: flex;
  padding-left: 4rem;
}

#left.column {
  width: 50vw;
  height: 100%;
  margin-top: 15rem;
  margin-right: 5rem;
}

#right.column {
  position: fixed;
  right: 0;
  width: 40vw;
  height: 100%;
}

#left .main-content section {
  border: 2.5px solid #fff;
  border-radius: 15px;
  margin-bottom: 1rem;
  padding: 1rem;
  text-transform: uppercase;
  background-color: #000;
}

#left .main-content section h1 {
  font-size: xx-large;
  margin-bottom: 0;
}

#main-page .column .main-content span {
  color: var(--span-color);
}

#main-page .column .subtext-container {
  display: none !important;
}

#main-page .column .main-content .events-container button {
  border: 2.5px solid #fff;
  border-radius: 15px;
  background-color: transparent;
  color: #fff;
  padding: 1rem 3rem;
  font-family: "Kallisto";
  font-size: large;
  text-transform: uppercase;
  cursor: pointer;

  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

#main-page .column .main-content .events-container h1 {
  font-size: xx-large;
}

#main-page .column .main-content .events-container button:hover {
  background-color: #ea040e;
  color: #fff;
  border: 2.5px solid #ea040e;
}

#main-page .column .main-content .subtext-container p {
  font-weight: bold;
}

#main-page .column .main-content .booking-container h3 {
  margin-bottom: 0;
}

#main-page .column .main-content .booking-container .content {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

#main-page .column .main-content .booking-container .calendar-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  background-color: var(--accent-color);
}

#main-page .column .main-content .booking-container .calendar-circle img {
  width: 75%;
}

#main-page .column .main-content .booking-container .calendar-circle a {
  text-align: center;
}

/*COMING SOON TEMP*/
#coming-soon-blur {
  position: absolute;
  margin-top: -2.5vh;
}

.session-selection {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.session-selection button {
  border: 2.5px solid #fff;
  border-radius: 15px;
  background-color: transparent;
  color: #fff;
  padding: 1rem 3rem;
  font-family: "Kallisto";
  text-transform: uppercase;
  cursor: pointer;
}

.session-selection button a {
  color: #fff;
  text-decoration: none;
}

@media only screen and (min-width: 375px) and (max-width: 768px) {
  .session-selection button {
    width: 100%;
  }
}

.session-duration-container,
.session-location-container {
  position: relative;
}

.session-duration-container button,
.session-location-container button {
  border: 2.5px solid #fff;
  border-radius: 15px;
  background-color: transparent;
  color: #fff;
  padding: 1rem 3rem;
  font-family: "Kallisto";
  text-transform: uppercase;
  cursor: pointer;
}

.session-duration-container .drop-down-content,
.session-location-container .drop-down-content {
  width: 100%;
}

.dropdown {
  display: inline-block;
}

.drop-down-content {
  position: absolute;
  background-color: transparent;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 3;
  border-radius: 15px;
  border: 2.5px solid #fff;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  backdrop-filter: blur(100px);
}

.drop-down-content.show {
  opacity: 1;
  transform: translateY(0);
  backdrop-filter: blur(100px);
  background-color: rgba(0, 0, 0, 0.9);
}

.drop-down-content a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-bottom: 2.5px solid #fff;
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-family: "Kallisto";
}

.drop-down-content a:last-child {
  border: none;
}

/* #main-page .column .main-content .booking-container h3, .booking-details, .calendar-circle {
  filter: blur(20px);
} */

@media (max-width: 768px) {
  #main-page {
    flex-direction: column;
    padding-top: 5rem;
    padding: 4rem 0 1rem;
    padding-left: 0;
  }

  #left.column {
    width: 100%;
    height: auto;
    margin-right: 0;
    z-index: 1;
    margin-top: 5rem;
  }

  #left .main-content section {
    margin: 1rem;
  }

  #main-page .column .main-content .subtext-container,
  #main-page .column .main-content .events-container,
  #main-page .column .main-content .booking-container,
  #main-page .column .main-content .about-container,
  #main-page .column .main-content .faq-container {
    background-color: transparent;
    text-align: center;
    backdrop-filter: blur(20px);
  }

  #left .main-c #main-page .column .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #main-page .column .subtext-container {
    display: block;
    border: none;
    backdrop-filter: blur(50px);
    padding: 1rem;
    margin: 0 auto;
    width: 70vw;
    border-radius: 15px;
  }

  #main-page .column .main-content .subtext-container p {
    width: 65vw;
    margin: auto;
    text-transform: none;
    font-size: 1.2rem;
  }

  #main-page .column .main-content .booking-container .content {
    flex-direction: column;
    padding: 0 0 2rem;
  }

  #main-page .column .main-content .about-container {
    padding: 2rem 0;
  }

  #main-page .column .main-content .about-container p {
    width: 70vw;
    margin: 1rem auto;
    font-size: 1.2rem;
  }

  #right.column {
    width: 100%;
    margin-top: 2rem;
    border-radius: 10%;
  }

  #main-page .column .main-content .subtext-container {
    position: fixed;
    margin: 0 auto;
    width: 100dvw;
  }

  #left .subtext-container {
    padding: 0 3rem;
    text-align: center;
  }

  #left .subtext-container p {
    font-size: x-large;
  }

  #main-page .column .main-content .booking-container .calendar-circle {
    margin-top: 2rem;
  }

  .session-location-container {
    width: 100%;
  }

  .session-duration-container {
    width: 100%;
  }
}
