#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(var(--header-blur));
}

#header a {
  text-decoration: none;
}

#header .title-container h1 {
  text-transform: uppercase;
  text-align: center;
}

#header .title-container h1:nth-child(1) {
  margin-bottom: 0;
}

#header .title-container h1:nth-child(2) {
  margin-top: 0;
}

#header .subtext-container {
  width: 20vw;
  margin-left: -20vw;
}

#header .menu-container button {
  margin-right: 10rem;
  border: 2.5px solid #fff;
  border-radius: 15px;
  background-color: transparent;
  color: #fff;
  padding: 1rem 3rem;
  font-family: "Kallisto";
  font-size: large;
  text-transform: uppercase;
  cursor: pointer;
}

.menu-container {
  position: relative;
}

.dropdown {
  display: inline-block;
}

/* What is this dropbtn? 

.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

*/

.drop-down-content {
  position: absolute;
  background-color: transparent;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 15px;
  border: 2.5px solid #fff;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  backdrop-filter: blur(100px);
  pointer-events: none;
}

.drop-down-content.show {
  opacity: 1;
  transform: translateY(0);
  pointer-events: all;
}

.drop-down-content a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-bottom: 2.5px solid #fff;
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-family: "Kallisto";
}

.drop-down-content a:last-child {
  border: none;
}

@media (max-width: 768px) {
  #header {
    flex-direction: row;
    padding: 0.5rem 1rem;
    font-size: 0.7rem;
    position: fixed;
  }

  #header .subtext-container {
    display: none;
  }

  #header .menu-container button {
    padding: 0.5rem 1.5rem;
    margin: 1rem 2rem;
  }
}

@media (min-width: 769px) and (max-width: 1920px) {
  #header .subtext-container {
    display: none;
  }
}
