#footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50vw;
}

#footer .copyrights span {
  font-family: "Kallisto";
  text-transform: uppercase;
}

#footer .socials ul {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  list-style: none;
}

#footer .socials ul > li {
  margin-left: 1rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  #footer {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
  }

  #footer .copyrights {
    margin-left: 1rem;
  }

  #footer .socials {
    margin-right: 1rem;
  }
}
